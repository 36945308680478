const url = {
    // 请求的host
    API_SERVER_HOST: "https://test.api.yunhaochefu.com",
    //请求的端口
    API_SERVER_PORT: "",
    //文件上传的地址
    fileUploadUrl: "https://test.api.yunhaochefu.com/ci-file-server/",
    //websoket
    WS_API_SERVER: "wss://test.websocket.yunhaochefu.com",

    //商城请求的域名
    SHOP_API_SERVE: "https://test.mall.seller.api.yunhaochefu.com",
    //商城文件上传
    SHOP_API_FILE_SERVE: "https://test.mall.base.api.yunhaochefu.com",
    // b端
    //商城请求的域名
    SHOP_B_API_SERVE: "https://test.company.mall.seller.api.yunhaochefu.com",
    //资料管理请求的域名
    TDMS_API_SERVE: "https://test.api.yunhaochefu.com",
    //右侧菜单栏不通过接口过滤
    debugMode: true,
    //门户的host
    PORTAL_API_SERVER_HOST: "https://test-api-2.laoniuche.cn/",
    //chatGpt
    DIFY_SERVER: "https://dify.laoniuche.cn",
}

export default url

import { useState, useRef, useEffect } from 'react'
import { createModel } from 'hox'
import { getMobileCar, getVehicleOne, getGPSHistory, getOrderTeamList, virtualList } from '@/service/homeService'
import { getToken } from '../../utils/dataStorage'
import { WS_API_SERVER } from '../../config/app'
import { message } from 'antd'
// import offline from '../../assets/images/offline_map_mark.png'
// d6f8bd0a07561ecb483d6040a6214044
// import busy from '../../assets/images/busy_map_mark.png'
// 602c0eb2677f966f792847ebfce372d5
// import wait from '../../assets/images/wait_map_mark.png'
// 2937ccf8bbb24f5a971db39c61035668
import close from '../../assets/images/close.png'
import { orderPispatchPlanOrderInfo } from '@/service/ordersService'
import {useHomePengddingOrderModel} from './homePengddingOrder'

const offline = `https://file-server.yunhaochefu.com/image/d6f8bd0a07561ecb483d6040a6214044`
const busy = `https://file-server.yunhaochefu.com/image/602c0eb2677f966f792847ebfce372d5`
const wait = `https://file-server.yunhaochefu.com/image/2937ccf8bbb24f5a971db39c61035668`

export const useHomeMapModel = createModel(function () {
    const {handleMapOrderIconClick, removeMarkerPengddingOrder, setRemoveMarkerPengddingOrder} = useHomePengddingOrderModel()

    let wsUrl = `${WS_API_SERVER}/ci-ws?authentication=${getToken()}`
    let tt = null
    const [showFull, setShowFull] = useState(false) //是否展示全屏

    // 地图相关的
    const mapRef = useRef({}) //地图容器
    const markersRef = useRef(null) //地图车辆容器
    const vehicleWindowInfoRef = useRef(null) //地图车辆文字容器
    const [mapCenter, setMapCenter] = useState({ longitude: 116.397451, latitude: 39.909187 }) //地图中心点
    const [markers, setMarkers] = useState([])
    // 待派单订单Markers
    const [pengdingOrderMarkers, setPengdingOrderMarkers] = useState([])
    const pengdingOrderMarkersRef = useRef(null) //地图订单容器

    const ws = useRef(null)

    const [vehicleList, setVehicleList] = useState([]) //车辆列表
    
    useEffect(() => {
        if(removeMarkerPengddingOrder){
            removePengdingOrderMarker(removeMarkerPengddingOrder)
        }
    }, [removeMarkerPengddingOrder])

    //初始化地图
    const initMap = () => {
        if (!document.getElementById('map-box')) {
            return setTimeout(() => {
                initMap()
            }, 100)
        }
        setTimeout(() => {
            mapRef.current = new window.TMap.Map(document.getElementById('map-box'), {
                center: new window.TMap.LatLng(mapCenter.latitude, mapCenter.longitude),
                zoom: 11,
                noClear: false,
                showControl: false,
            })
        }, 500)
    }

    //自动刷新
    var auto = null
    const handleAutoRefresh = (val) => {
        ws.current.close()
        createWebSocket()
        if (val) {
            auto = setInterval(() => {
                ws.current.close()
                createWebSocket()
            }, 300000)
        } else {
            clearInterval(auto)
        }
    }

    const updateMakers = (makers) => {
        if (markersRef.current) {
            markersRef.current.forEach((maker) => {
                maker.destroy()
            })
        }

        markersRef.current = makers.map((item) => {
            let {
                position: { latitude, longitude },
                carStatus,
                teamName,
                course,
            } = item
            let url = null
            if (carStatus === 'offline') {
                url = offline
            } else if (carStatus === 'busy') {
                url = busy
            } else if (carStatus === 'wait') {
                url = wait
            }
            course = item.course - 90
            return new window.TMap.InfoWindow({
                map: mapRef.current,
                position: new window.TMap.LatLng(latitude, longitude), //设置信息框位置
                enableCustom: true,
                content: `<div
                id="marker--${latitude}--${longitude}"
                style="width: 58px;
                    height: 26px;
                    color: #000;
                    textAlign: center;
                    position: relative;
                    cursor: pointer;"
                 >
                    <img
                        style="width: 58px;
                        height: 26px; 
                        transform: rotate(${course}deg);"
                        src="${url}" />
                    <div  
                        style="
                        width:58px;
                        height: 26px;
                        transform: rotate(${course}deg); position: absolute;
                        left: 0px;
                        top: 0;">
                    <span
                        style="
                            width:58px;
                            height: 26px;
                            font-size: 8px;
                            color: #fff;
                            line-height: 13px;
                            text-align: center;
                            transform: ${course > 90 || course < -90 ? 'rotate(180deg)' : 'rotate(0deg)'};
                            padding-${course > 90 || course < -90 ? 'left' : 'right'}: 20px;
                            display: block;
                            borderRadius: 10px;">  
                        ${teamName && teamName.substring(0, 8)}
                    </span>
                </div>
               
            </div>`, //设置信息框内容
            })
        })
        markersRef.current.forEach((maker, index) => {
            maker.on('click', (evt) => {
                console.log(evt)
            })
        })

        makers.forEach((item) => {
            let {
                position: { latitude, longitude },
            } = item
            window.document.getElementById(`marker--${latitude}--${longitude}`).onclick = function () {
                openVehicleWindowInfo(item)
            }
        })
    }

    const openVehicleWindowInfo = async (item) => {
        console.log(item)
        if (vehicleWindowInfoRef.current && vehicleWindowInfoRef.current.destroy) {
            vehicleWindowInfoRef.current.destroy()
        }
        const { position } = item
        setMapCenter(position)
        if (item.vehicleId == null) {
            return
        }
        let vehicle = await getVehicleOne(item.vehicleId)
        let orderList = await getOrderTeamList({
            pageSize: 1,
            pageNum: 1,
            searchItem: {
                teamId: item.teamId,
                orderFlag: 0,
            },
        })
        if (vehicle.retCode !== 200) {
            return message.error(vehicle.retMsg)
        }
        if (orderList.retCode !== 200) {
            return message.error(orderList.retMsg)
        }

        let geocoder = new window.TMap.service.Geocoder()
        // 地址逆解析
        geocoder
            .getAddress({ location: new window.TMap.LatLng(position.latitude, position.longitude) }) // 将给定的坐标位置转换为地址
            .then((res) => {
                const checkedVehicle = {
                    ...vehicle.retData,
                    formattedAddress: res.result.address,
                    ...item,
                    orderList: orderList.retData.list,
                }
                vehicleWindowInfoRef.current = new window.TMap.InfoWindow({
                    map: mapRef.current,
                    position: new window.TMap.LatLng(position.latitude, position.longitude), //设置信息框位置
                    enableCustom: true,
                    offset: { x: 0, y: -40 },
                    content: `<div><div class="window-info">
                    <img class="window-close" id="window-info-close" src=${close} />
                    <div class="window-info-title">
                        ${checkedVehicle.vehicleCode}
                        <span>${checkedVehicle.teamName}</span>
                    </div>
                    <div class="window-info-main">
                        <div class="window-info-tab-title">
                            <span id="window-info-tab-title-base" class="window-info-active">
                                基本信息
                            </span>
                            <span id="window-info-tab-title-vehicle">
                                车辆信息
                            </span>
                        </div>
                        <div class="window-info-content"  id="window-info-tab-title-base-box">
                            <div>
                                <span>车辆牌照：</span>
                                ${checkedVehicle?.vehicleCode || ''}
                            </div>
                            <div>
                                <span>当前位置：</span>
                                ${checkedVehicle?.formattedAddress || ''}
                            </div>
                            <div>
                                <span>速度：</span>
                                ${checkedVehicle?.speed || ''}
                            </div>
                            <div>
                                <span>报警：</span>
                                ${checkedVehicle?.alarm === 1 ? '是' : '否'}
                            </div>
                            <div>
                                <span>方向：</span>
                                ${checkedVehicle?.course || ''}
                            </div>
                            <div>
                                <span>状态：</span>
                                ${checkedVehicle?.status || ''}
                            </div>
                        </div>
                        <div class="window-info-content"  id="window-info-tab-title-vehicle-box">
                            <div>
                                <span>车辆牌照：</span>
                                ${checkedVehicle?.vehicleCode || ''}
                            </div>
                            <div>
                                <span>车辆品牌：</span>
                                ${checkedVehicle?.vehicleBrand || ''}
                            </div>
                            <div>
                                <span>车辆类型：</span>
                                ${checkedVehicle?.vehicleType || ''}
                            </div>
                            <div>
                                <span>联系电话：</span>
                                ${checkedVehicle?.userPhone || ''}
                            </div>
                            <div>
                                <span>设备号：</span>
                                ${checkedVehicle?.vehicleEquipmentCode || ''}
                            </div>
                            <div>
                                <span>VIN号：</span>
                                ${checkedVehicle?.vehicleVin || ''}
                            </div>
                        </div>
                    </div>
                </div></div>`, //设置信息框内容
                })
            })

        setWindowInfoTabStyle()
    }

    const setWindowInfoTabStyle = () => {
        const base = window.document.getElementById(`window-info-tab-title-base`)
        const close = window.document.getElementById(`window-info-close`)
        const vehicle = window.document.getElementById(`window-info-tab-title-vehicle`)
        const baseBox = window.document.getElementById(`window-info-tab-title-base-box`)
        const vehicleBox = window.document.getElementById(`window-info-tab-title-vehicle-box`)
        if (base && vehicle && baseBox && vehicleBox) {
            base.onclick = function () {
                base.setAttribute('class', 'window-info-active')
                vehicle.setAttribute('class', '')
                baseBox.style.display = 'block'
                vehicleBox.style.display = 'none'
            }
            vehicle.onclick = function () {
                vehicle.setAttribute('class', 'window-info-active')
                base.setAttribute('class', '')
                vehicleBox.style.display = 'block'
                baseBox.style.display = 'none'
            }
            close.onclick = function () {
                vehicleWindowInfoRef.current.destroy()
            }
        } else {
            setTimeout(() => {
                setWindowInfoTabStyle()
            }, 1000)
        }
    }

    // 获取车辆
    const getVehicles = async (searchItem = {}) => {
        let markers = []

        await Promise.all([
            getMobileCar({
                pageSize: 10000,
                pageNum: 1,
                searchItem,
            }).then((res) => {
                res.retData.list.forEach((item) => {
                    if (item.lng && item.lat) {
                        markers.push({
                            position: {
                                longitude: item.lng,
                                latitude: item.lat,
                            },
                            ...item,
                        })
                    }
                })
                setVehicleList(res.retData.list)
            }),
            virtualList().then((res) => {
                res.retData.forEach((item) => {
                    if (item.lng && item.lat) {
                        let lng = parseFloat(item.lng) + parseFloat((Math.random() / 200).toFixed(6))
                        let lat = parseFloat(item.lat) + parseFloat((Math.random() / 200).toFixed(6))
                        markers.push({
                            ...item,
                            position: {
                                longitude: lng,
                                latitude: lat,
                            },
                        })
                    }
                })
            }),
        ])
            .then((result) => console.log('获取车辆完成'))
            .catch((e) => console.log('获取车辆错误' + e))
        setMarkers(markers)
    }

    const handleAddOrderMarker = (orderId) => {
        orderPispatchPlanOrderInfo(orderId).then((res) => {
            const {orderStatus} = res.retData
            //判断订单是待派单状态
            if (orderStatus === 'pending_order') {
            setPengdingOrderMarkers([...pengdingOrderMarkers, res.retData])
            }
        })
    }

    //更新订单提示
    const updatePengdingOrderMarkers = (orders) => {
        if( Object.keys(mapRef.current).length === 0){
            return setTimeout(() => {
                updatePengdingOrderMarkers(orders)
            }, 500);
        }
        if (pengdingOrderMarkersRef.current) {
            pengdingOrderMarkersRef.current.forEach((maker) => {
                maker.destroy()
            })
        }
        pengdingOrderMarkersRef.current = orders.map((item,index) => {
            const { orderId, repairLat, repairLng } = item
            if(index === orders.length -1){//最后一个设置为地图中心点
                setMapCenter({ longitude: repairLng, latitude: repairLat })
            }
            return new window.TMap.InfoWindow({
                map: mapRef.current,
                position: new window.TMap.LatLng(repairLat, repairLng), //设置信息框位置
                enableCustom: true,
                offset: { x: 0, y: -40 },
                content: `<img class="window-order-icon" id="${orderId}"  src='https://file-server.yunhaochefu.com/image/46161acd73a2bdac1ca63bd24bcb2792' />`
            })
            
        })

        orders.forEach((item) => {
            const { orderId, repairLat, repairLng } = item
            window.document.getElementById(orderId).onclick = function () {
               setMapCenter({ longitude: repairLng, latitude: repairLat })
               handleMapOrderIconClick(orderId)
            }
        })
    }

    //移除订单提示
    const removePengdingOrderMarker = (orderId) => {
        setPengdingOrderMarkers(pengdingOrderMarkers.filter(i => i.orderId !== orderId))
        setRemoveMarkerPengddingOrder('')
    }

    //一，创建ws
    const createWebSocket = () => {
        try {
            if (getToken()) {
                wsUrl = `${WS_API_SERVER}/ci-ws?authentication=${getToken()}`
                ws.current = new WebSocket(wsUrl)
                // ws.current.markers = []
                // ws.current.setMarkers = (arr) => {
                //     setMarkers(arr)
                //     ws.current.markers = arr
                // }
                // ws.current.orderMarkers = []
                // ws.current.setOrderMarkers = (arr) => {
                //     // setOrderMarkers(arr)
                //     ws.current.orderMarkers = arr
                // }
                init()
            } else {
                console.log('没有token')
                reconnect(wsUrl)
            }
        } catch (e) {
            console.log('长链接网络异常')
            message('长链接网络异常,请刷新重试')
            reconnect(wsUrl)
        }
    }
    //二，调用init方法，该方法内把一些监听事件封装如下：当网络断开的时候，会先调用onerror，onclose事件可以监听到，会调用reconnect方法进行重连操作。正常的情况下，是先调用onopen方法的，当接收到数据时，会被onmessage事件监听到。
    const init = () => {
        ws.current.onclose = function () {
            console.log('链接关闭')
            reconnect(wsUrl)
        }
        ws.current.onerror = function (err) {
            console.log('发生异常了')
            reconnect(wsUrl)
        }
        ws.current.onopen = function () {
            if (ws && ws.current && ws.current.send) {
                // 订阅新订单推送
                ws.current.send(
                    JSON.stringify({
                        router: 'topic_router_order_create',
                        data: {
                            action: 'subscription',
                        },
                    })
                )
            }
            //心跳检测重置
            heartCheck.start()
        }
        ws.current.onmessage = ({ data }) => {
            data = JSON.parse(data)
            if (data.topic === 'topic_order_created') {
                let orderId = data.data.orderId
            }
            heartCheck.start()
        }
    }

    let lockReconnect = false //避免重复连接
    //三， 重连操作 reconnect：如果网络断开的话，会执行reconnect方法，使用了一个定时器，4秒后会重新创建一个新的websocket链接，重新调用createWebSocket函数，重新会执行及发送数据给服务器端。
    const reconnect = (url) => {
        console.log('重连')
        if (lockReconnect) {
            return
        }
        lockReconnect = true
        //没连接上会一直重连，设置延迟避免请求过多
        tt && clearTimeout(tt)
        tt = setTimeout(function () {
            createWebSocket(url)
            lockReconnect = false
            // window.location.reload()
        }, 4000)
    }

    //四，心跳检测
    let heartCheck = {
        timeout: 10000,
        timeoutObj: null,
        serverTimeoutObj: null,
        start() {
            // console.log('start');
            let self = this
            this.timeoutObj && clearTimeout(this.timeoutObj)
            this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj)
            this.timeoutObj = setTimeout(() => {
                //这里发送一个心跳，后端收到后，返回一个心跳消息，
                var msg = { router: 'heartbeat_router' }
                msg = JSON.stringify(msg)
                // console.log('发送一个心跳', msg);
                ws.current.send(msg)
                self.serverTimeoutObj = setTimeout(() => {
                    ws.current.close()
                }, self.timeout)
            }, this.timeout)
        },
    }

    return {
        showFull,
        setShowFull,
        handleAutoRefresh,
        initMap,
        getVehicles,
        vehicleList,
        markers,
        updateMakers,
        mapCenter,
        mapRef,
        openVehicleWindowInfo,
        handleAddOrderMarker,
        pengdingOrderMarkers,
        updatePengdingOrderMarkers,
        setPengdingOrderMarkers,
        removePengdingOrderMarker
    }
})

import React, { useState, useEffect, useRef } from 'react'
import close from '@/assets/images/index-popup-close.png'
import bigBg from '@/assets/images/index-popup-big-bg.png'
import sendBg from '@/assets/images/index-popup-send-bg.png'
import smallBg from '@/assets/images/index-popup-big-small.png'
import bigCattle from '@/assets/images/index-popup-big-cattle.gif'
import smallCattle from '@/assets/images/index-popup-small-cattle.gif'
import success from '@/assets/images/index-popup-success.png'
import { getUserInfo } from '@/utils/dataStorage'
import styles from './popup.module.less'
import { Form, Row, Col, Select, Spin } from 'antd'
import { getPcodeDict } from '@/utils/dataStorage'
import { useHomeDispatchInfoModel } from './homeDispatchInfo'
import { useHomePengddingOrderModel } from './homePengddingOrder'
import ReactMarkdown from 'react-markdown'
import './input.less'
export default () => {
    const {
        form,
        org,
        handleChangeOrg,
        organType,
        teamInfo,
        handleChangeTeam,
        teamQuery,
        teamQuerys,
        memberInfo,
        handleChangeMember,
        memberQuery,
        memberQuerys,
        mainMemberList,
        dispatchInit,
    } = useHomeDispatchInfoModel()
    const {
        startPopupVisible,
        dispatchPopupVisible,
        successPopupVisible,
        difyInfoMsg,
        difyLoading,
        handleToHandDispatch,
        autoDispatchTime,
        handleToAutoDispatch,
        handleHandDispatch,
        handleCloseStartPopupVisible,
        handleCloseDispatchPopupVisible,
        handleCloseSuccessPopup,
        chooseDispatchTypePopupVisible,
        handleCloseChooseDispatchTypePopupVisible,
        difyInfoJson,
    } = useHomePengddingOrderModel()
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    //
    const chatWindowRef = useRef(null)

    //消息变化 储存到ref中，并吧窗口滚动到底部
    useEffect(() => {
        scrollToBottom()
    }, [difyInfoMsg])

    useEffect(() => {
        if (dispatchPopupVisible) {
            dispatchInit()
            form.resetFields()
            let { organId } = getUserInfo()
            let defaltForm = { organId }
            form.setFieldsValue(defaltForm)
        }
    }, [dispatchPopupVisible])

    // 滚动到底部的函数
    const scrollToBottom = () => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight
        }
    }

    return (
        <div className={styles['box']} hidden={!startPopupVisible && !dispatchPopupVisible && !successPopupVisible && !chooseDispatchTypePopupVisible}>
            {/* 新消息弹窗 */}
            {startPopupVisible && (
                <div className={styles['news-box']}>
                    <img className={styles['common-close']} src={close} onClick={handleCloseStartPopupVisible} />
                    <img className={styles['news-box-bg']} src={bigBg} />
                    <img className={styles['news-box-cattle']} src={bigCattle} />
                    <div className={styles['news-box-info']}>
                        <p className={styles['news-box-info-title']}>您好!有新的订单到来啦!🎉🎉</p>
                        {difyInfoMsg ? (
                            <div id="homeNewsBoxInfoReactMarkdown" className={styles['news-box-info-main']} ref={chatWindowRef}>
                                <ReactMarkdown>{difyInfoMsg}</ReactMarkdown>
                                {difyLoading && <text className={styles['loading-icon']}></text>}
                            </div>
                        ) : (
                            <div className={styles['news-box-info-main']}>
                                云豪智能小牛正在紧急处理中，根据您的订单详情，我们正快速匹配最专业的维修团队来为您提供贴心服务。请您稍作等待，我们的智能系统正在为您精心安排。
                                <div className={styles['loading']}>
                                    <div className={styles['dot']}></div>
                                    <div className={styles['dot']}></div>
                                    <div className={styles['dot']}></div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {/* 选择派单方式弹窗 */}
            {chooseDispatchTypePopupVisible && (
                <div className={styles['choose-dispatch-type-box']}>
                    <img className={styles['common-close']} src={close} onClick={handleCloseChooseDispatchTypePopupVisible} />
                    <img className={styles['choose-dispatch-type-box-bg']} src={smallBg} />
                    <img className={styles['choose-dispatch-type-box-cattle']} src={smallCattle} />
                    <div className={styles['choose-dispatch-type-box-info']}>
                        <p className={styles['choose-dispatch-type-title']}>根据您所报修的位置,系统为您匹配合到合适的维修团队：</p>
                        <p className={styles['choose-dispatch-type-content']}>
                            {difyInfoJson?.teamName}-{difyInfoJson?.teamName}
                        </p>
                        <div className={styles['news-box-btns']}>
                            <div className={styles['news-box-hand']} onClick={handleToHandDispatch}>
                                手动派单
                            </div>
                            {autoDispatchTime > -1 && (
                                <div className={styles['news-box-auto']} onClick={handleToAutoDispatch}>
                                    <span>{autoDispatchTime}S</span>自动派单
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {/* 派单弹窗 */}
            {dispatchPopupVisible && (
                <div className={styles['dispatch-box']}>
                    <img className={styles['common-close']} src={close} onClick={handleCloseDispatchPopupVisible} />
                    <img className={styles['dispatch-box-bg']} src={sendBg} />
                    <img className={styles['dispatch-box-cattle']} src={smallCattle} />
                    <div className={styles['dispatch-main']} id="home-index-dispath">
                        <Form form={form} {...formItemLayout} style={{ flex: 1 }}>
                            <Row gutter={20}>
                                <Col span={24}>
                                    <Form.Item label="机构" name="organId" rules={[{ required: true, message: '请选择机构' }]}>
                                        <Select placeholder="请选择机构" style={{ width: '100%' }} onChange={handleChangeOrg} disabled={organType !== 3}>
                                            {org.map((item) => (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.organName}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="团队" name="teamId" rules={[{ required: true, message: '请选择团队' }]}>
                                        <Select
                                            showSearch
                                            placeholder="请选择团队"
                                            notFoundContent={teamInfo.fetching ? <Spin size="small" /> : null}
                                            filterOption={false}
                                            onChange={handleChangeTeam}
                                            onFocus={() => teamQuery()}
                                            onSearch={teamQuerys}
                                            style={{ width: '100%' }}
                                        >
                                            {teamInfo.data.map((item) => (
                                                <Select.Option value={item.value} key={item.value}>
                                                    {item.text}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="团队成员" name="teamUserIds" rules={[{ required: true, message: '请选择团队成员' }]}>
                                        <Select
                                            showSearch
                                            mode="multiple"
                                            placeholder="请选择团队成员"
                                            notFoundContent={memberInfo.fetching ? <Spin size="small" /> : null}
                                            filterOption={false}
                                            onChange={handleChangeMember}
                                            onFocus={() => memberQuery()}
                                            onSearch={memberQuerys}
                                            style={{ width: '100%' }}
                                        >
                                            {memberInfo.data.map((item) => (
                                                <Select.Option value={item.value} key={item.value}>
                                                    {item.text}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="主维修人" name="majorUserId" rules={[{ required: true, message: '请选择主维修人' }]}>
                                        <Select placeholder="请选择主维修人" style={{ width: '100%' }}>
                                            {mainMemberList.map((item) => (
                                                <Select.Option value={item.value} key={item.value}>
                                                    {item.text}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <>
                                    <Col span={24}>
                                        <Form.Item
                                            label="服务方式"
                                            name="serviceMethod"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请选择服务方式',
                                                },
                                            ]}
                                        >
                                            <Select placeholder="请选择服务方式" style={{ width: '100%' }}>
                                                {getPcodeDict('012').children.map((item) => (
                                                    <Select.Option value={item.dictCode} key={item.id}>
                                                        {item.title}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="故障类型"
                                            name="serviceType"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请选择故障类型',
                                                },
                                            ]}
                                        >
                                            <Select placeholder="请选择故障类型" style={{ width: '100%' }}>
                                                {getPcodeDict('011').children.map((item) => (
                                                    <Select.Option value={item.dictCode} key={item.id}>
                                                        {item.title}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="工单类型"
                                            name="orderType"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请选择工单类型',
                                                },
                                            ]}
                                        >
                                            <Select placeholder="请选择工单类型" style={{ width: '100%' }}>
                                                {getPcodeDict('014').children.map((item) => (
                                                    <Select.Option value={item.dictCode} key={item.id}>
                                                        {item.title}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </>
                            </Row>
                        </Form>
                        <div className={styles['dispatch-box-dispatch-btns']}>
                            <div className={styles['dispatch-box-dispatch']} onClick={handleHandDispatch}>
                                确定
                            </div>
                            <div className={styles['dispatch-box-cancel']} onClick={handleCloseDispatchPopupVisible}>
                                取消
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* 成功弹窗 */}
            {successPopupVisible && (
                <div className={styles['success-box']}>
                    <img className={styles['common-close']} src={close} onClick={handleCloseSuccessPopup} />
                    <img className={styles['success-box-bg']} src={smallBg} />
                    <img className={styles['success-box-cattle']} src={smallCattle} />
                    <div className={styles['success-box-info']}>
                        <img src={success} />
                        <p>工单已派单成功</p>
                    </div>
                </div>
            )}
        </div>
    )
}
